import Layout from 'layout'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

const Inicio = React.lazy(() => import('pages/inicio'))
const MinhasPropostas = React.lazy(() => import('pages/minhas'))
const NotFound = React.lazy(() => import('pages/notFound'))
const Registro = React.lazy(() => import('pages/registro'))
// const Ideias = React.lazy(() => import('pages/ideias'))
const Ideia = React.lazy(() => import('pages/ideia'))
const IdeiaForm = React.lazy(() => import('pages/ideiaForm'))
const ComoFunciona = React.lazy(() => import('pages/comoFunciona'))

const routeData = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{ path: '/', element: <Navigate to='/inicio' /> },
			{ path: 'inicio', element: <Inicio /> },
			{ path: 'registro', element: <Registro /> },
			{ path: 'como-funciona', element: <ComoFunciona /> },
			// { path: 'ideias', element: <Ideias /> },
			{ path: 'ideias/nova', element: <IdeiaForm /> },
			{ path: 'ideias/:ideia_id', element: <Ideia /> },
			{ path: 'minhas-ideias', element: <MinhasPropostas /> },
			{ path: '404', element: <NotFound /> },
			{ path: '*', element: <Navigate to='/404' /> }
		],
	},
	{
		path: '*',
		element: <Navigate to='/404' replace />
	},
]

export { routeData }

export default function Routes() {
	return useRoutes(routeData)
}
