function HTMLData({ descricao = 'Não informado', ...props }) {
	return (
		<span
			{...props}
			dangerouslySetInnerHTML={{
				__html: descricao.replace(/(?:\r\n|\r|\n)/g, '<br />'),
			}}
		/>
	)
}

export { HTMLData }

