import { useBase, useLoading, useUtil } from 'hooks'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { api } from 'services'

// const USER_KEY = process.env.REACT_APP_KEY

function useUsuario() {
	const SUFIX = 'USUARIO'
	const URL = '/usuarios'
	const baseHook = useBase({ sufix: SUFIX, url: URL })
	const dispatch = useDispatch()
	const { loadingStart, loadingStop } = useLoading()
	const { handleError } = useUtil()
	const navigate = useNavigate()


	async function post(body) {
		try {
			loadingStart()
			const { data } = await api.post(`${URL}`, body)
			dispatch({ type: `POST${SUFIX}`, payload: data })
			navigate('/')
			toast('Operação realizada com sucesso.')
		} catch (error) {
			handleError(error)
		} finally {
			loadingStop()
		}
	}

	return {
		...baseHook,
		post,
	}
}


export { useUsuario }

