import { useDispatch } from 'react-redux'

function useLoading() {
	const dispatch = useDispatch()

	const loadingStart = async () => {
		dispatch({ type: 'LOADING', payload: true })
	}
	const loadingStop = async () => {
		dispatch({ type: 'LOADING', payload: false })
	}

	return {
		loadingStart,
		loadingStop,
	}
}

export { useLoading }
