import { useDispatch } from 'react-redux'

function useModal() {
	const dispatch = useDispatch()

	async function showModal(modalName, show = false, params = null) {
		dispatch({
			type: `MODAL_${modalName.toUpperCase()}`,
			payload: { show: show, params: params },
		})
	}

	return {
		showModal,
	}
}

export { useModal }
