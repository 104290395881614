import { useLoading, useUtil } from 'hooks'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { api } from 'services'

const defaultData = {
	data: [],
	pagination: {
		page: 1,
		pageSize: 20,
		rowCount: 0,
		pageCount: 0,
	},
}

function useBase({ url, sufix }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { loadingStart, loadingStop } = useLoading()
	const { handleError } = useUtil()

	async function all(field, term = '') {
		try {
			loadingStart()
			const { data } = await api.get(`${url}/all`, {
				params: { field: field, term: term },
			})
			dispatch({ type: `ALL${sufix}`, payload: data })
		} catch (error) {
			dispatch({ type: `ALL${sufix}`, payload: [] })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function fetch(field, page = 1, term = '') {
		try {
			loadingStart()
			if (page === -1) dispatch({ type: `FETCH${sufix}`, payload: defaultData })
			else {
				const { data } = await api.get(url, {
					params: {
						field: field,
						page: page,
						term: term,
					},
				})
				dispatch({ type: `FETCH${sufix}`, payload: data })
			}
		} catch (error) {
			dispatch({ type: `FETCH${sufix}`, payload: defaultData })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function fetchById(id = null) {
		try {
			loadingStart()
			if (!id) dispatch({ type: `ONE${sufix}`, payload: null })
			else {
				const { data } = await api.get(`${url}/${id}`)
				dispatch({ type: `ONE${sufix}`, payload: data })
			}
		} catch (error) {
			dispatch({ type: `ONE${sufix}`, payload: null })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function save(body) {
		try {
			loadingStart()
			if (body.id) {
				const { data } = await api.put(`${url}/${body.id}`, body)
				dispatch({ type: `PUT${sufix}`, payload: data })
			} else {
				const { data } = await api.post(`${url}`, body)
				if (data.id) {
					dispatch({ type: `POST${sufix}`, payload: data })
					let path = location.pathname.split('/')
					path.pop()
					navigate(`${path.join('/')}/${data.id}`)
				} else navigate('/')
			}
			toast('Operação realizada com sucesso.')
		} catch (error) {
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function post(body) {
		try {
			loadingStart()
			const { data } = await api.post(`${url}`, body)
			dispatch({ type: `POST${sufix}`, payload: data })
			toast('Operação realizada com sucesso.')
		} catch (error) {
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function put(id, body) {
		try {
			loadingStart()
			const { data } = await api.put(`${url}/${id}`, body)
			dispatch({ type: `PUT${sufix}`, payload: data })
			toast('Operação realizada com sucesso.')
		} catch (error) {
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function del(id) {
		try {
			loadingStart()
			await api.delete(`${url}/${id}`)
			dispatch({ type: `DEL${sufix}`, payload: null })
			toast('Operação realizada com sucesso.')
		} catch (error) {
			dispatch({ type: `DEL${sufix}`, payload: null })
			handleError(error)
		} finally {
			loadingStop()
		}
	}

	return {
		all,
		fetch,
		fetchById,
		save,
		post,
		put,
		del,
	}
}

export { useBase, defaultData }

