import { Login } from 'components'
import { useModal } from 'hooks'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

function ModalGeneric() {
	const { showModal } = useModal()
	const { modalGeneric } = useSelector(s => s.modal)
	const { loggedUser } = useSelector(s => s.auth)

	return (
		<Modal
			show={modalGeneric}
			onHide={showModal.bind(this, 'generic', false)}
			backdrop="static"
			keyboard={true}
			centered
			size="md"
		>
			<Modal.Header closeButton>
				<Modal.Title>Login</Modal.Title>
			</Modal.Header>
			<Login />
		</Modal>
	)
}

export { ModalGeneric }

