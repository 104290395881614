import { useAuth } from 'hooks'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

function Login() {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const { login } = useAuth()

	return (
		<React.Fragment>
			<form noValidate autoComplete='off' onSubmit={handleSubmit(login)}>
				<Modal.Body>
					<label htmlFor="email" className='form-label my-2'>E-mail</label>
					<input
						id="email"
						type="email"
						className={`form-control form-control-lg ${errors.email && 'is-invalid'}`}
						{...register('email', { required: true })}
					/>
					<label htmlFor="senha" className='form-label my-2'>Senha</label>
					<input
						id="senha"
						type="password"
						className={`form-control form-control-lg ${errors.senha && 'is-invalid'}`}
						{...register('senha', { required: true })}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button type="submit" className='btn btn-primary'>
						Login
					</button>
				</Modal.Footer>
			</form>
		</React.Fragment>
	)
}

export { Login }

