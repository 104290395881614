import axios from 'axios'
const API_URL = process.env.REACT_APP_API
const USER_KEY = process.env.REACT_APP_KEY

const api = axios.create({ baseURL: API_URL })

api.interceptors.request.use(async (config) => {
	const data = localStorage.getItem(USER_KEY)
	if (data) {
		const { token } = JSON.parse(data)
		if (token) config.headers.Authorization = token
	}

	return config
})

api.interceptors.response.use(null, (error) => {
	if (error && error.response.status === 415) {
		localStorage.removeItem(USER_KEY)
		window.location.replace('/')
	}
	if (error) throw error
})

export { api }

