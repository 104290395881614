const initialState = {
	loggedUser: undefined,
}

const auth = (state = initialState, action) => {
	const { payload, type } = action
	switch (type) {
		case 'LOGGED':
			return { ...state, loggedUser: payload }
		default:
			return state
	}
}

export { auth }
