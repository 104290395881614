import { ProgressBar, ShareWhatsApp } from 'components'
import { format } from 'date-fns'
import { useModal, useProposta } from 'hooks'
import qs from 'query-string'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

function ListIdeias() {
  const location = useLocation()
  const { fetchByStatus } = useProposta()
  const { showModal } = useModal()
  const { page = 1, term = '' } = qs.parse(location.search)
  const { propostas, pagination } = useSelector(s => s.proposta)

  useEffect(fetchByStatus, [])

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-items-center col-sm-8 mx-auto border-bottom border-3 border-primary'>
        <h1 className='fs-3 fw-bold'>
          Ideias abertas aguardando seu apoio
        </h1>
        <Link to='/ideias/nova' className='text-decoration-none'>
          Registrar nova ideia
        </Link>
      </div>
      {/* não localizadas */}
      {propostas.length === 0 && (
        <React.Fragment>
          <div className='col-sm-8 mx-auto my-2 p-2 d-flex justify-content-center bg-info bg-opacity-10 border-top border-bottom border-1 border-info'>
            <p className='fs-5 fw-bold'>
              Sem idéias localizadas
            </p>
          </div>
        </React.Fragment>
      )}
      {/* registros */}
      {propostas.map((el, ix) => {
        const { id, data_expira, titulo, apoiadores } = el
        return (
          <React.Fragment key={ix}>
            <div className='col-sm-8 mx-auto my-2 p-2 d-flex flex-column gap-2 bg-info bg-opacity-10 border-top border-bottom border-1 border-info'>
              <div className="d-flex flex-column">
                <Link className='text-decoration-none' to={`/ideias/${id}`}>
                  <p className='fs-4 fw-bold lh-1'>{titulo}</p>
                </Link>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='lh-1'>Expira em: {format(new Date(data_expira), 'dd/MM/yyyy')}</p>
                  <ShareWhatsApp id={id} />
                </div>
              </div>
              <ProgressBar max={5000} value={apoiadores.length} />
            </div>
          </React.Fragment>
        )
      })}
      {/* paginacao */}
      {pagination.pageCount > pagination.page && (
        <React.Fragment>
          <div className='col-sm-8 mx-auto my-3 p-3 d-flex justify-content-center'>
            <Link to={`${location.pathname}?page=${page + 1}&term=${term}`} className='btn btn-secondary'>
              mais registros
            </Link>
          </div>
        </React.Fragment>
      )}
    </React.Fragment >
  )
}

export { ListIdeias }

