import { Loading, ModalGeneric } from 'components'
import { useAuth, useUtil } from 'hooks'
import { default as React, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Header = React.lazy(() => import('./_header.jsx'))
const Footer = React.lazy(() => import('./_footer'))

function Layout() {
	const { getUser } = useUtil()
	const { setUser } = useAuth()
	const { loggedUser } = useSelector(s => s.auth)
	const user = getUser()

	useEffect(() => {
		if (!loggedUser) setUser(user)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	return (
		<React.Suspense fallback={<Loading />}>
			<Loading />
			<ModalGeneric />
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar={true}
				newestOnTop={true}
				closeOnClick
			/>
			<div className='d-flex flex-column min-vh-100'>
				<Header />
				<main>
					<Outlet />
				</main>
				<Footer />
			</div>
		</React.Suspense>
	)
}

export default Layout

