const sufix = 'USUARIO'
const initialState = {
	usuarios: [],
	usuario: null,
	pagination: {
		rowCount: 0,
		page: 1,
		pageCount: 0,
	},
}

const usuario = (state = initialState, action) => {
	const { payload, type } = action
	switch (type) {
		case `ALL${sufix}`:
			return { ...state, usuarios: payload }
		case `FETCH${sufix}`: {
			const { data, pagination } = payload
			return {
				...state,
				usuarios: pagination.page === 1 ? data : [...state.usuarios, ...data],
				pagination: pagination,
			}
		}
		case `ONE${sufix}`:
		case `POST${sufix}`:
		case `PUT${sufix}`:
			return { ...state, usuario: payload }
		case `DEL${sufix}`:
			return { ...state, usuario: null }
		default:
			return state
	}
}

export { usuario }
