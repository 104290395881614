import { useLoading, useModal, useUtil } from 'hooks'
import { useDispatch } from 'react-redux'
import { api } from 'services'

const USER_KEY = process.env.REACT_APP_KEY

function useAuth() {
	const dispatch = useDispatch()
	const { loadingStart, loadingStop } = useLoading()
	const { handleError, confirm, alert, getUser } = useUtil()
	const { showModal } = useModal()
	const API_URL = '/auth'

	function getRoutes() {
		const user = getUser()
		if (user) {
			return [].concat.apply(
				[],
				user.perfis.map((e) => e.perfil.permissoes)
			)
		}
		return []
	}
	async function login({ email, senha }) {
		try {
			loadingStart()
			const { data } = await api.post(API_URL, { email, senha })
			localStorage.setItem(USER_KEY, JSON.stringify(data))
			dispatch({ type: 'LOGGED', payload: { id: data.id, nome: data.nome } })
			showModal('generic', false)
		} catch (error) {
			dispatch({ type: 'LOGGED', payload: null })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function logout() {
		confirm('Deseja realmente sair?', ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					loadingStart()
					localStorage.removeItem(USER_KEY)
					dispatch({ type: 'LOGGED', payload: null })
				} catch (error) {
					handleError(error)
				} finally {
					loadingStop()
				}
			}
		})
	}
	async function setUser(user) {
		dispatch({ type: 'LOGGED', payload: user })
	}
	async function changePassword({ senha, confirmacao }) {
		const { id = 0 } = getUser()
		try {
			loadingStart()
			await api.put(`/seguranca/usuarios/${id}/pwd`, { senha, confirmacao })
			alert('Senha alterada com sucesso.')
		} catch (error) {
			handleError(error)
		} finally {
			loadingStop()
		}
	}

	return {
		getRoutes,
		login,
		logout,
		changePassword,
		setUser,
	}
}

export { useAuth }

