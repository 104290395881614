import { combineReducers } from 'redux'
import { proposta } from './legislativo'
import { auth, usuario } from './seguranca'
import { loading } from './_loading'
import { modal } from './_modal'

const reducers = combineReducers({
	loading,
	modal,
	auth,
	usuario,
	proposta,
})

export default reducers
