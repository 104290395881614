const sufix = 'PROPOSTA'
const initialState = {
	propostas: [],
	proposta: null,
	pagination: {
		rowCount: 0,
		page: 1,
		pageCount: 0,
	},
}

const proposta = (state = initialState, action) => {
	const { payload, type } = action
	switch (type) {
		case `ALL${sufix}`:
			return { ...state, propostas: payload }
		case `FETCH${sufix}`: {
			const { data = [], pagination = initialState.pagination } = payload || {}
			return {
				...state,
				propostas: pagination.page === 1 ? data : [...state.propostas, ...data],
				pagination: pagination,
			}
		}
		case `ONE${sufix}`:
		case `POST${sufix}`:
		case `PUT${sufix}`:
			return { ...state, proposta: payload }
		case `DEL${sufix}`:
			return { ...state, proposta: null }
		default:
			return state
	}
}

export { proposta }

