import React from 'react'

function ProgressBar({ max = 100, value = 0 }) {
  const percet = Math.floor(value * 100 / max)
  return (
    <React.Fragment>
      <div className="progress mt-2" style={{ height: 32 }}>
        <div
          className="progress-bar"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow={percet}
          aria-valuemin="0"
          aria-valuemax={max}
          style={{ width: `${percet}%` }}
        >
          {percet}%
        </div>
      </div>
      <div className="mb-2 d-flex justify-content-between flex-grow-1">
        <span className='lh-1'>
          {value.toLocaleString('pt-BR')} apoios
        </span>
        <span className='lh-1'>
          {max.toLocaleString('pt-BR')}
        </span>
      </div>
    </React.Fragment>
  )
}

export { ProgressBar }

