import axios from 'axios'
import { useLoading } from 'hooks'
// import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const USER_KEY = process.env.REACT_APP_KEY

function useUtil() {
	const { loadingStart, loadingStop } = useLoading()

	function arraySort(data, attribute, reverse = false) {
		if (reverse)
			return data.sort((a, b) => {
				if (a[attribute] > b[attribute]) return -1
				if (a[attribute] < b[attribute]) return 1
				return 0
			})
		return data.sort((a, b) => {
			if (a[attribute] < b[attribute]) return -1
			if (a[attribute] > b[attribute]) return 1
			return 0
		})
	}
	function alert(msg) {
		Swal.fire({
			title: 'e-Cidadania informa...',
			// icon: 'info',
			text: msg,
			showConfirmButton: false,
			timer: 3000,
		})
	}
	function confirm(msg, callBack) {
		Swal.fire({
			title: 'e-Cidadania pergunta...',
			// icon: 'info',
			text: msg,
			showCloseButton: false,
			showConfirmButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Confirma',
			cancelButtonText: 'Cancelar',
		}).then(callBack)
	}
	function handleError(error) {
		try {
			if (typeof error === 'string') alert(error)
			else if (error.response && error.response.data)
				alert(error.response.data.join(', '))
			else if (error.data) alert(error.data.join(', '))
			else if (error.message) alert(error.message)
			else throw Error()
		} catch (error) {
			alert('Ops... ocorreu um erro.')
		}
	}
	function getUser() {
		return JSON.parse(localStorage.getItem(USER_KEY))
	}
	function isNumber(number) {
		return /^[-]?\d+$/.test(number)
	}
	function isValidCPF(cpf) {
		var Soma
		var Resto
		Soma = 0
		if (cpf === "00000000000") return false

		for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
		Resto = (Soma * 10) % 11

		if ((Resto === 10) || (Resto === 11)) Resto = 0
		if (Resto !== parseInt(cpf.substring(9, 10))) return false

		Soma = 0
		for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
		Resto = (Soma * 10) % 11

		if ((Resto === 10) || (Resto === 11)) Resto = 0
		if (Resto !== parseInt(cpf.substring(10, 11))) return false
		return true
	}
	async function getAddress(cep) {
		const defaultData = { logradouro: '', cidade: '', cidade_ibge: '', estado: '', bairro: '', lat: '', lng: '', ddd: '' }
		try {
			loadingStart()
			const { data } = await axios.get(`https://cep.awesomeapi.com.br/json/${cep}`)
			return {
				logradouro: data.address,
				cidade: data.city,
				cidade_ibge: data.city_ibge,
				estado: data.state,
				bairro: data.district,
				lat: data.lat,
				lng: data.lng,
				ddd: data.ddd,
			}
		} catch (error) {
			return defaultData
		} finally {
			loadingStop()
		}
	}

	return {
		arraySort,
		alert,
		confirm,
		handleError,
		getUser,
		isNumber,
		getAddress,
		isValidCPF,
	}
}

export { useUtil }

