
function ShareWhatsApp({ id, space = null }) {
  const msg = "\n\nOlá, estou compartilhando este projeto para pedir o seu apoio. Posso contar com você?"

  return (
    <span>
      <a
        href={`https://wa.me/?text=${window.location.origin}/ideias/${id} ${window.encodeURIComponent(msg)}`}
        target='_blank'
        rel="noreferrer"
        title='Compartilar no whatsapp'
        className={`btn btn-success ${space && 'ms-2'}`}
      >
        <i className='fa fa-whatsapp' />
      </a>
    </span>
  )
}

export { ShareWhatsApp }

