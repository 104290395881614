import { defaultData, useBase, useLoading, useUtil } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { api } from 'services'

function useProposta() {
	const SUFIX = 'PROPOSTA'
	const URL = '/propostas'
	const baseHook = useBase({ sufix: SUFIX, url: URL })
	const { handleError, alert, confirm } = useUtil()
	const { loadingStart, loadingStop } = useLoading()
	const { loggedUser } = useSelector(s => s.auth)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	async function fetchByUser(field, page = 1, term = '') {
		try {
			loadingStart()
			if (page === -1) dispatch({ type: `FETCH${SUFIX}`, payload: defaultData })
			else {
				const { data } = await api.get(`${URL}/byuser`, { params: { field: field, page: page, term: term, }, })
				dispatch({ type: `FETCH${SUFIX}`, payload: data })
			}
		} catch (error) {
			dispatch({ type: `FETCH${SUFIX}`, payload: defaultData })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function fetchByStatus(field, page = 1, term = '') {
		try {
			loadingStart()
			if (page === -1) dispatch({ type: `FETCH${SUFIX}`, payload: defaultData })
			else {
				const { data } = await api.get(`${URL}/bystatus`, { params: { field: field, page: page, term: term, }, })
				dispatch({ type: `FETCH${SUFIX}`, payload: data })
			}
		} catch (error) {
			dispatch({ type: `FETCH${SUFIX}`, payload: defaultData })
			handleError(error)
		} finally {
			loadingStop()
		}
	}
	async function post(body) {
		try {
			body.autor_id = loggedUser.id
			const { data } = await api.post(`${URL}`, body)
			dispatch({ type: `POST${SUFIX}`, payload: data })
			alert('Ideia registrada com sucesso.')
			navigate('/')
		} catch (error) {
			handleError(error)
		}
	}
	async function follow(proposta_id, apoiador_id) {
		confirm('Deseja realmente registrar seu apoio a essa ideia?', async function ({ isConfirmed }) {
			if (isConfirmed)
				try {
					loadingStart()
					const { data } = await api.post('/apoiadores', { proposta_id, apoiador_id })
					dispatch({ type: `ONE${SUFIX}`, payload: data })
					alert('Apoio registrado com sucesso.')
				} catch (error) {
					handleError(error)
				} finally {
					loadingStop()
				}
		})
	}

	return {
		...baseHook,
		fetchByUser,
		fetchByStatus,
		post,
		follow,
	}
}

export { useProposta }

