const initialState = {
	loading: false,
}

const loading = (state = initialState, action) => {
	const { payload, type } = action
	switch (type) {
		case 'LOADING':
			return { ...state, loading: payload }
		default:
			return state
	}
}

export { loading }
