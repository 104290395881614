import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/scss/bootstrap.scss'
import { Loading } from 'components'
import 'font-awesome/scss/font-awesome.scss'
import { default as React } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes'
import store from 'store'
import 'styles/style.scss'

export default function App() {
	return (
		<Provider store={store}>
			<React.Suspense fallback={<Loading />}>
				<HelmetProvider>
					<Router>
						<Routes />
					</Router>
				</HelmetProvider>
			</React.Suspense>
		</Provider>
	)
}
