function InfoIdeias() {
  return (
    <div className=' col-sm-4 p-3 my-4 border border-2 border-primary rounded mx-auto d-flex justify-content-center align-items-center gap-3 bg-secondary text-white'>
      <i className='fa fa-exclamation-circle fa-2x ' />
      Ao receber 5.000 apoios, a ideia se tornará uma Sugestão Legislativa, e será debatida pelos Vereadores.
    </div>
  )
}

export { InfoIdeias }

