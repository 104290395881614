import { useSelector } from 'react-redux'
import './styles.scss'

function Loading() {
	const { loading } = useSelector((s) => s.loading)

	if (loading)
		return (
			<div id='loading'>
				<div className='spinner-border pt-1 text-center' role='status'>
					<span className='visually-hidden'>Carregando...</span>
				</div>
			</div>
		)
	return null
}

export { Loading }

