const initialState = {
	modalGeneric: false,
	modalLogin: false,
	modalRegister: false,
	params: null,
}

const modal = (state = initialState, action) => {
	const { payload, type } = action
	switch (type) {
		case 'MODAL_GENERIC':
			return { ...state, modalGeneric: payload.show, params: payload.params }
		case 'MODAL_LOGIN':
			return { ...state, modalLogin: payload.show, params: payload.params }
		case 'MODAL_REGISTER':
			return { ...state, modalRegister: payload.show, params: payload.params }
		default:
			return state
	}
}

export { modal }

