import hero from 'assets/hero-img.svg'
import logo from 'assets/logo.png'

function BannerHome() {
  return (
    <section id="home" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row gy-4 d-flex justify-content-between">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <img id="logo" src={logo} alt='Logo' title='Logo' className='img-fluid' />
            <h2>
              Sua forma de participação direta no parlamento municipal
            </h2>
            <p>Facere distinctio molestiae nisi fugit tenetur repellat non praesentium nesciunt optio quis sit odio nemo quisquam. eius quos reiciendis eum vel eum voluptatem eum maiores eaque id optio ullam occaecati odio est possimus vel reprehenderit</p>
            {/* 
            <form className="form-search d-flex align-items-stretch mb-3" >
              <input type="text" className="form-control" placeholder="informe um termo para pesquisar" />
              <button type="submit" className="btn btn-primary">Pesquisar</button>
            </form> 
            */}
          </div>
          <div className="col-lg-5 order-1 order-lg-2 hero-img">
            <img src={hero} className="img-fluid mb-3 mb-lg-0" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export { BannerHome }

